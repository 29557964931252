import React, { useState } from "react"
import { graphql } from "gatsby"
import SEO from "../components/Seo"
import Layout from "../components/Layout"

import TableOfContents from "../components/docsTable/TableOfContents"
import { Container, Row, Col } from "react-bootstrap"
import styled from "styled-components"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const [clicked, setClicked] = useState(false)
  const [className, setClassName] = useState("")

  const handleClick = () => {
    console.log(clicked)
    if (clicked) {
      setClicked(false)
      setClassName("")
    } else {
      setClicked(true)
      setClassName("toggled")
    }
  }

  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>
      <SEO title={frontmatter.title} description={frontmatter.description} />

      <StyledDiv>
        <div
          id="wrapper"
          className={className + " "}
          style={{ background: "#f5f7f9" }}
        >
          <Row bsPrefix="d-block d-lg-none">
            <Col className="text-center pt-3">
              <button
                className="btn btn-secondary pt-2"
                id="sidebarToggle"
                onClick={handleClick}
              >
                azszero Documentation
              </button>
            </Col>
          </Row>

          <div id="sidebar-wrapper">
            <TableOfContents />
          </div>
          <div id="page-content-wrapper">
            <Container fluid>
              <Row className="mx-auto pt-5">
                <Col
                  lg={12}
                  dangerouslySetInnerHTML={{ __html: html }}
                  style={{ maxWidth: "750px" }}
                />
              </Row>
            </Container>
          </div>
        </div>
      </StyledDiv>
    </Layout>
  )
}

const StyledDiv = styled.div`
  #wrapper {
    padding-left: 0;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    /* overflow-x: hidden; */
  }

  #wrapper.toggled {
    padding-left: 290px;
  }

  #sidebar-wrapper {
    position: absolute;
    left: 290px;
    width: 0;
    height: 100%;
    // height: -moz-available; /* WebKit-based browsers will ignore this. */
    height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
    //height: fill-available;

    margin-left: -290px;
    overflow-y: auto;
    overflow-x: hidden;
    background: #f5f7f9;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;

    align-items: stretch;
    border-right: 1px solid #e6ecf1;
    /* padding-left: calc((100% - 1448px) / 2); */
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;

    /* padding-left: 16px; */
    border-left: 1px solid transparent;

    & ::-webkit-scrollbar {
      width: 4px;
    }
    & ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
    }

    & ::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
      border-radius: 2px;
    }
  }

  #wrapper.toggled #sidebar-wrapper {
    width: 290px;
  }

  #page-content-wrapper {
    /* position: absolute; */
    padding: 15px;

    h2,
    h3 {
      padding-top: 50px;
      border-top: 2px solid #e6ecf1;

      margin: 0px auto;
      padding: 0px 24px 24px;

      color: #242a31;
      margin: 0;
      display: flex;
      position: relative;
      margin-top: 73px;
      margin-bottom: 24px;

      font-size: 24px;
      font-family: Content-font, Roboto, sans-serif;
      font-weight: 700;
      line-height: 1.5;
    }
    h4,
    h5 {
      color: #242a31;
      margin: 0;
      display: flex;
      position: relative;
      margin-top: 32px;
      margin-bottom: 24px;
      flex: 1;

      font-size: 20px;
      font-family: Content-font, Roboto, sans-serif;
      font-weight: 700;
      line-height: 1.5;
    }
    h1 {
      color: #242a31;
      width: 100%;
      border: none;
      margin: 0;
      display: block;
      outline: none;
      padding: 0;
      background: none;
      border-radius: 3px;

      font-size: 32px;
      font-family: Content-font, Roboto, sans-serif;
      font-weight: 500;
      line-height: 1.5;

      border-bottom: 2px solid #e6ecf1;
      padding-bottom: 25px;
      margin-bottom: 50px;
    }
    p {
      margin: 0;
      position: relative;
      margin-bottom: 24px;

      font-size: 16px;
      font-family: Content-font, Roboto, sans-serif;
      font-weight: 400;
      line-height: 1.625;
    }
  }

  #wrapper.toggled #page-content-wrapper {
    position: absolute;
    margin-right: -290px;
  }

  /* Sidebar Styles */

  .sidebar-nav {
    position: absolute;
    top: 0;
    width: 290px;
    margin: 0;
    padding: 0;
    list-style: none;
    left: 0;
  }

  .sidebar-nav li {
    text-indent: 20px;
    line-height: 40px;
  }

  .sidebar-nav li a {
    display: block;
    text-decoration: none;
    color: #999999;
  }

  .sidebar-nav li a:hover {
    text-decoration: none;
    color: #fff;
    background: rgba(255, 255, 255, 0.2);
  }

  .sidebar-nav li a:active,
  .sidebar-nav li a:focus {
    text-decoration: none;
  }

  .sidebar-nav > .sidebar-brand {
    height: 65px;
    font-size: 18px;
    line-height: 60px;
  }

  .sidebar-nav > .sidebar-brand a {
    color: #999999;
  }

  .sidebar-nav > .sidebar-brand a:hover {
    color: #fff;
    background: none;
  }

  @media (min-width: 992px) {
    #wrapper {
      padding-left: 290px;
    }

    #wrapper.toggled {
      padding-left: 0;
    }

    #sidebar-wrapper {
      width: 290px;
    }

    #wrapper.toggled #sidebar-wrapper {
      width: 0;
    }

    #page-content-wrapper {
      padding: 20px;
      position: relative;

      margin: 0px 88px;

      h2,
      h3 {
        padding-top: 50px;
        border-top: 2px solid #e6ecf1;
        /* margin-top: 50px; */

        color: #242a31;
        margin: 0;
        display: flex;
        position: relative;
        margin-top: 50px;
        margin-bottom: 24px;

        font-size: 24px;
        font-family: Content-font, Roboto, sans-serif;
        font-weight: 700;
        line-height: 1.5;
      }
      h4,
      h5 {
        color: #242a31;
        margin: 0;
        display: flex;
        position: relative;
        margin-top: 32px;
        margin-bottom: 24px;
        flex: 1;

        font-size: 20px;
        font-family: Content-font, Roboto, sans-serif;
        font-weight: 700;
        line-height: 1.5;
      }
      h1 {
        color: #242a31;
        width: 100%;
        border: none;
        margin: 0;
        display: block;
        outline: none;
        padding: 0;
        background: none;
        border-radius: 3px;

        font-size: 32px;
        font-family: Content-font, Roboto, sans-serif;
        font-weight: 500;
        line-height: 1.5;

        border-bottom: 2px solid #e6ecf1;
        padding-bottom: 25px;
        margin-bottom: 50px;
      }
      p {
        margin: 0;
        position: relative;
        margin-bottom: 24px;

        font-size: 16px;
        font-family: Content-font, Roboto, sans-serif;
        font-weight: 400;
        line-height: 1.625;
      }
      blockquote {
        color: #74818d;
        margin: 0;
        padding: 0;
        border-left: 4px solid;
        border-color: #e6ecf1;
        padding-left: 12px;
        margin-bottom: 24px;

        color: #74818d;
        margin: 0;
        position: relative;
        margin-bottom: 24px;

        font-size: 16px;
        font-family: Content-font, Roboto, sans-serif;
        font-weight: 400;
        line-height: 1.625;
      }
    }

    #wrapper.toggled #page-content-wrapper {
      position: relative;
      margin-right: 0;
    }
  }
`

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        description
      }
    }
  }
`
