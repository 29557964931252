import React from "react"
import slugify from "slugify"
import { Link } from "gatsby"
import styled from "styled-components"
import Scroll from "../icons/Scroll"

const TableOfContents = () => {
  const tableOfContents = [
    { dir: "Introduction", tocs: ["What is azszero"] },

    {
      dir: "Sales",
      tocs: [
        "Sales Overview",
        "ASINs Sales Breakdown",
        "ASINs Not Sold",
        "ASINs Overview Charts",
      ],
    },

    {
      dir: "Products",
      tocs: ["Products", "Vendors", "Components"],
    },

    {
      dir: "Inventory",
      tocs: [
        "Inventory Managment",
        "Inventory Problems",
        "Global Inventory Settings",
        "ASIN Inventory Settings",
      ],
    },

    {
      dir: "Inbounds",
      tocs: ["FBA Inbounds Shipments", "FBA Arrived Shipments"],
    },

    {
      dir: "Refunds",
      tocs: ["Refunds Breakdown", "Refunds Details"],
    },
    {
      dir: "Feedback",
      tocs: ["Customers Feedback"],
    },

    {
      dir: "Performance",
      tocs: ["ASINs Ranks", "ASINs Lost Ranking"],
    },
    {
      dir: "Actions",
      tocs: ["What are Actions"],
    },
    {
      dir: "Amazon Product Page Builder",
      tocs: [
        "Introduction",
        "Creating New Amazon Product",
        "Amazon Product Title",
        "Amazon Product Keywords",
        "Amazon Product Features",
        "Amazon Product Description",
        "Keywords Hint and Generator",
      ],
    },
  ]

  return (
    <>
      <ul className="sidebar-nav">
        <li className="sidebar-brand">azszero Documentation</li>

        {tableOfContents.map(tableOfContent => (
          <>
            <li className="pb-3">
              <StyledSpanDir key={slugify(tableOfContent.dir, { lower: true })}>
                {tableOfContent.dir}
              </StyledSpanDir>
              {/* {tableOfContent.tocs.map(toc => (
                <>
                  <Link to={"/docs/" + slugify(toc, { lower: true })}>
                    <StyledSpanFile>{toc}</StyledSpanFile>
                  </Link>
                </>
              ))} */}

              {tableOfContent.tocs.map(toc => (
                <>
                  <Link
                    key={slugify(toc, { lower: true })}
                    to={"/docs/" + slugify(toc, { lower: true })}
                  >
                    <StyledSpanFile>{toc}</StyledSpanFile>
                  </Link>
                </>
              ))}
            </li>
          </>
        ))}
      </ul>
      <Scroll showBelow={250} />
    </>
  )
}
const StyledSpanDir = styled.span`
  margin: 0;
  padding: 0;
  margin-left: -1px;
  display: -webkit-flex;
  color: lightgray;
  background-color: #696868;
  border: 1px solid transparent;
  display: flex;
  padding: 7px 24px 7px 16px;
  position: relative;
  align-items: center;
  border-right: 0;
  text-decoration: none;
  -webkit-box-align: center;
  flex: 1;
  word-break: break-word;
  font-size: 12px;
  font-family: Content-font, Roboto, sans-serif;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 1.2px;
  text-transform: uppercase;
`
const StyledSpanFile = styled.span`
  margin: 0;
  display: block;
  padding: 0;
  position: relative;

  margin-left: -1px;

  color: darkslategrey;
  border: 1px solid transparent;
  cursor: pointer;
  padding: 7px 24px 7px 16px;
  position: relative;
  align-items: center;
  border-right: 0;
  text-decoration: none;
  -webkit-box-align: center;

  flex: 1;
  font-size: 14px;
  word-break: break-word;
  font-family: Content-font, Roboto, sans-serif;
  font-weight: 500;
  line-height: 1.5;

  border-left: 1px solid transparent;

  & :hover {
    background-color: #e6ecf1;
    color: slategray;
  }
`

export default TableOfContents
